import React from "react";
import Layout from "../../components/Layout";
import SectionContainerOld from "../../components/SectionContainerOld";
import { Typography } from "@material-ui/core";

export default () => (
  <Layout>
    <SectionContainerOld color="white">
      <Typography variant="h4">Thank you for contacting us! We will message you within the next 48 hours.</Typography>
    </SectionContainerOld>
  </Layout>
);
